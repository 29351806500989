import * as Sentry from "@sentry/browser";

if(window.FTC_ENV.sentryDsn) {
    Sentry.init({
        dsn: window.FTC_ENV.sentryDsn,
        release: window.FTC_ENV.appVersion,
        environment: window.FTC_ENV.env,
        ignoreErrors: ["[object Object]"]
    });
    if(window.FTC_ENV.serverId) {
        Sentry.setTag("server_name", window.FTC_ENV.serverId)
    }
    if(window.FTC_ENV.bootId) {
        Sentry.setTag("boot_id", window.FTC_ENV.bootId)
    }
    if(window.FTC_ENV.userAccountId) {
        Sentry.setUser({id: window.FTC_ENV.userAccountId})
    }
}
// For unbundled parts of the frontend
window.Sentry = Sentry;